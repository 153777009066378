<app-page>
  <page-header></page-header>
  <page-content>
    <div id="contact-us" class="my-5">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mt-4">
            <h6 class="text-primary fw-bold">تواصل معنا</h6>
            <h2 class="fw-bold mb-5">لا تتردد في الكتابة</h2>

            <form action="#">
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <input
                      placeholder="الاسم"
                      required
                      type="text"
                      class="form-control form-control-lg focus-shadow-none"
                      id="name"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <input
                      placeholder="البريد الالكتروني"
                      required
                      type="email"
                      class="form-control form-control-lg focus-shadow-none"
                      id="email"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <input
                      placeholder="الموضوع"
                      required
                      type="text"
                      class="form-control form-control-lg focus-shadow-none"
                      id="subject"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <input
                      placeholder="رقم الهاتف"
                      required
                      type="tel"
                      class="form-control form-control-lg focus-shadow-none"
                      id="tel"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3">
                    <textarea
                      placeholder="الرسالة"
                      required
                      rows="6"
                      class="form-control form-control-lg focus-shadow-none"
                      id="message"
                    ></textarea>
                  </div>
                </div>
                <div class="col-md-12 text-end">
                  <button class="btn btn-primary btn-lg px-5" type="submit">
                    ارسال
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="col mt-4">
            <h6 class="text-primary fw-bold">هل تحتاج الى مساعدة؟</h6>
            <h2 class="fw-bold">ابق على تواصل معنا</h2>

            <p>
              آراؤكم ومقترحاتكم مهمة بالنسبة لنا، نأمل منكم عدم التردد في
              إرسالها إلينا وسيتم أخذها بعين الاعتبار والتنفيذ، ولكم خالص الشكر
              والتقدير:
            </p>

            <div class="contact-list mt-4 fs-6">
              <div class="d-flex align-items-center mb-3">
                <div
                  class="icon-wrapper me-3 bg-primary text-white d-flex flex-grow-0 flex-shrink-0 align-items-center justify-content-center rounded-2"
                  style="width: 50px; height: 50px"
                >
                  <i class="fa-solid fa-phone fa-flip-horizontal fs-5"></i>
                </div>
                <div class="fw-bold">
                  <p class="mb-0 small">هل لديك استفسار اتصل بنا؟</p>
                  <a class="small" href="tel: 00966114800555">00966114800555</a>
                </div>
              </div>

              <div class="d-flex align-items-center mb-3">
                <div
                  class="icon-wrapper me-3 bg-primary text-white d-flex flex-grow-0 flex-shrink-0 align-items-center justify-content-center rounded-2"
                  style="width: 50px; height: 50px"
                >
                  <i class="fa-solid fa-envelope fa-flip-horizontal fs-5"></i>
                </div>
                <div class="fw-bold">
                  <p class="mb-0 small">ارسل لنا بريد الكتروني</p>
                  <a class="small" href="mailto: abegs@abegs.org">
                    abegs@abegs.org
                  </a>
                </div>
              </div>

              <div class="d-flex align-items-center mb-3">
                <div
                  class="icon-wrapper me-3 bg-primary text-white d-flex flex-grow-0 flex-shrink-0 align-items-center justify-content-center rounded-2"
                  style="width: 50px; height: 50px"
                >
                  <i
                    class="fa-solid fa-location-dot fa-flip-horizontal fs-5"
                  ></i>
                </div>
                <div class="fw-bold">
                  <p class="mb-0 small">عنوان المراسلة</p>
                  <p class="mb-0 small">عنوان المراسلة</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
