import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ReplaySubject, takeUntil } from "rxjs";
import { News } from "src/app/interfaces/landing-page";
import { NewsPage, NewsPageData } from "src/app/interfaces/news-page";
import { CMSService } from "src/app/services/cms.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-news-activities-page",
  templateUrl: "./news-activities-page.component.html",
  styleUrls: ["./news-activities-page.component.scss"],
})
export class NewsActivitiesPageComponent implements OnInit {
  paginationButtonsNumber!: number;
  page = 0;
  totalDocuments!: number;
  loading!: boolean;
  newsPage!: NewsPage;
  news!: News[];
  unsubscribe$ = new ReplaySubject(1);
  newsLimit = environment.limit.newsLimit;
  constructor(private route: ActivatedRoute, private cmsService: CMSService) {}

  ngOnInit() {
    const page = this.route.snapshot.data["page"] as NewsPageData;
    this.newsPage = page.meta;
    const news = page.data.news;
    this.news = [...news];
    this.totalDocuments = page.data.pagingNews[0].count.id;
    this.paginationButtonsNumber = Math.ceil(
      this.totalDocuments / this.newsLimit
    );
  }

  showItemsByPage(page: number) {
    const listItems = document.getElementsByClassName("page-item");
    for (let i = 0; i < listItems.length; i++) {
      const item = listItems[i];
      if (page === i + 1) {
        item.classList.add("active");
      } else {
        item.classList.remove("active");
      }
    }
    this.cmsService
      .getActivitiesByPage(page)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result: any) => {
          this.totalDocuments = result.pagingNews[0].count.id;
          this.news = [...result.news];
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }
}
