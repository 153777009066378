import {Component, Input} from "@angular/core";
import {Breadcrumb} from "src/app/interfaces/breadcrumb";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent {
  @Input() data!: Breadcrumb[];
}
