import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  AwardBudget,
  AwardMembership,
  HomePage,
  LandingPageData,
} from "src/app/interfaces/landing-page";

@Component({
  selector: "app-landing-schools-membership",
  templateUrl: "./landing-schools-membership.component.html",
  styleUrls: ["./landing-schools-membership.component.scss"],
})
export class LandingSchoolsMembershipComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  awardBuget!: AwardBudget;
  awardMembership!: AwardMembership;
  awardBudgetAndCategories: any;
  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as LandingPageData;
    this.awardBuget = page.data.awardBudget;
    this.awardMembership = page.data.awardMembership;
    this.prepareBudgetCategories(this.awardBuget);
  }

  prepareBudgetCategories(awardBudget: AwardBudget) {
    this.awardBudgetAndCategories = [
      {
        title: awardBudget.bronzeTitle,
        description: awardBudget.bronzeDescription,
        image: awardBudget.bronzeImage,
        topMargin: "5rem",
        colorCode: "#CD7F32",
      },
      {
        title: awardBudget.goldenTitle,
        description: awardBudget.goldenDescription,
        image: awardBudget.goldenImage,
        topMargin: "0rem",
        colorCode: "#FFD700",
      },
      {
        title: awardBudget.silverTitle,
        description: awardBudget.silverDescription,
        image: awardBudget.silverImage,
        topMargin: "3rem",
        colorCode: "#C0C0C0",
      },
    ];
  }
}
