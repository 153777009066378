import { environment } from "src/environments/environment";

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import ar from "@angular/common/locales/ar";

import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatPaginatorModule } from "@angular/material/paginator";

import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { InMemoryCache } from "@apollo/client/core";

import { LightgalleryModule } from "lightgallery/angular";

import { InfiniteScrollModule } from "ngx-infinite-scroll";

import { AppRoutingModule } from "./app-routing.module";

import { FlickityDirective } from "./directives/flickity.directive";
import { SlickItemDirective } from "./directives/slick-item.directive";
import { CounterDirective } from "./directives/counter.directive";

import { ImageLinkPipe } from "./pipes/image-link.pipe";
import { FileLinkPipe } from "./pipes/file-link.pipe";
import { FileTypePipe } from "./pipes/file-type.pipe";

import { AppComponent } from "./app.component";
import { CopyrightComponent } from "./components/shared/copyright/copyright.component";
import { FooterLinksComponent } from "./components/shared/footer-links/footer-links.component";
import { FontsShareComponent } from "./components/shared/fonts-share/fonts-share.component";
import { SlickCarouselComponent } from "./components/shared/slick-carousel/slick-carousel.component";
import { LoaderComponent } from "./components/shared/loader/loader.component";
import { PageComponent } from "./components/shared/page/page.component";
import { PageContentComponent } from "./components/shared/page/page-content/page-content.component";
import { PageHeaderComponent } from "./components/shared/page/page-header/page-header.component";
import { PageFooterComponent } from "./components/shared/page/page-footer/page-footer.component";
import { NavbarComponent } from "./components/shared/navbar/navbar.component";
import { HeroComponent } from "./components/shared/hero/hero.component";
import { BreadcrumbComponent } from "./components/shared/breadcrumb/breadcrumb.component";

import { YouTubePlayerModule } from "@angular/youtube-player";
import { LandingPageComponent } from "./components/pages/landing-page/landing-page.component";
import { LandingAboutUsComponent } from "./components/pages/landing-page/landing-about-us/landing-about-us.component";
import { LandingIntroComponent } from "./components/pages/landing-page/landing-intro/landing-intro.component";
import { LandingNewsActivitiesComponent } from "./components/pages/landing-page/landing-news-activities/landing-news-activities.component";
import { LandingSchoolsMembershipComponent } from "./components/pages/landing-page/landing-schools-membership/landing-schools-membership.component";
import { NewsActivitiesPageComponent } from "./components/pages/news-activities-page/news-activities-page.component";
import { SingleNewsPageComponent } from "./components/pages/news-activities-page/single-news-page/single-news-page.component";
import { ContactUsComponent } from "./components/pages/contact-us/contact-us.component";

const angular = [
  BrowserModule,
  BrowserAnimationsModule,
  HttpClientModule,
  FormsModule,
  ReactiveFormsModule,
];

const material = [
  MatDatepickerModule,
  MatInputModule,
  MatFormFieldModule,
  MatIconModule,
  MatButtonModule,
  MatPaginatorModule,
  YouTubePlayerModule,
];

const thirdParties = [ApolloModule, LightgalleryModule, InfiniteScrollModule];

const directives = [FlickityDirective, SlickItemDirective, CounterDirective];

const pipes = [ImageLinkPipe, FileLinkPipe, FileTypePipe];

const components = [
  AppComponent,
  CopyrightComponent,
  FooterLinksComponent,
  FontsShareComponent,
  SlickCarouselComponent,
  LoaderComponent,
  PageComponent,
  PageContentComponent,
  PageHeaderComponent,
  PageFooterComponent,
  NavbarComponent,
  HeroComponent,
  BreadcrumbComponent,
  LandingPageComponent,
  LandingAboutUsComponent,
  LandingIntroComponent,
  LandingNewsActivitiesComponent,
  LandingSchoolsMembershipComponent,
  NewsActivitiesPageComponent,
  SingleNewsPageComponent,
  ContactUsComponent,
];

registerLocaleData(ar);

@NgModule({
  declarations: [...directives, ...pipes, ...components],
  imports: [...angular, ...material, ...thirdParties, AppRoutingModule],
  providers: [
    ...pipes,
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink) {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: environment.graphqlURI,
          }),
        };
      },
      deps: [HttpLink],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
