import {isPlatformBrowser} from "@angular/common";
import {Directive, OnInit, OnDestroy, ElementRef, PLATFORM_ID, Inject, Host} from "@angular/core";
import {SlickCarouselComponent} from "../components/shared/slick-carousel/slick-carousel.component";

@Directive({
  selector: "[slickItem]",
})
export class SlickItemDirective implements OnInit, OnDestroy {
  constructor(
    public el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: string,
    @Host() private carousel: SlickCarouselComponent,
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.carousel.addSlide(this);
    }
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      this.carousel.removeSlide(this);
    }
  }
}
