<div class="container">
  <div class="row">
    <div class="col-md-9 mx-auto">
      <section id="font-share-section" class="my-5 py-4">
        <div class="d-flex flex-row justify-content-between gap-4 flex-row">
          <div id="modify-font" class="d-flex align-items-center gap-2 text-light-gray">
            <div class="h6 mb-0">حجم الخط</div>
            <button class="btn btn-outline-light-gray btn-sm p-0" (click)="increaseFontSize()">
              <i class="fa-solid fa-plus"></i>
            </button>
            <button class="btn btn-outline-light-gray btn-sm p-0" (click)="decreaseFontSize()">
              <i class="fa-solid fa-minus"></i>
            </button>
          </div>
          <div id="social-share" class="d-flex align-items-center gap-1">
            <div class="h6 mb-0 text-light-gray">
              <i class="fa-solid fa-share mx-1"></i>
              شارك
            </div>

            <a href="https://web.whatsapp.com:/send?text={{ url }}" class="mx-1" target="_blank" style="color: #3ac34c">
              <i class="fa-brands fa-whatsapp"></i>
            </a>
            <a
              href="https://www.linkedin.com/sharing/share-offsite/?url={{ url }}"
              class="mx-1"
              target="_blank"
              style="color: #007bb5"
            >
              <i class="fa-brands fa-linkedin-in"></i>
            </a>
            <a href="http://www.facebook.com/share.php?u={{ url }}" class="mx-1" target="_blank" style="color: #39579a">
              <i class="fa-brands fa-facebook-f"></i>
            </a>
            <a href="http://twitter.com/home?status={{ url }}" class="mx-1" target="_blank" style="color: #69ace0">
              <i class="fa-brands fa-twitter"></i>
            </a>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
