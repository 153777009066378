import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { News } from "src/app/interfaces/landing-page";
import {
  SingleNewsPage,
  SingleNewsPageData,
} from "src/app/interfaces/single-news-page";

@Component({
  selector: "app-single-news-page",
  templateUrl: "./single-news-page.component.html",
  styleUrls: ["./single-news-page.component.scss"],
})
export class SingleNewsPageComponent implements OnInit {
  singleNewsPage!: SingleNewsPage;
  constructor(private route: ActivatedRoute) {}
  singleNews!: News;
  ngOnInit() {
    const page = this.route.snapshot.data["page"] as SingleNewsPageData;
    this.singleNewsPage = page.meta;

    this.singleNews = page.data.singleNews;
  }
}
