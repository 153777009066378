import {Pipe, PipeTransform} from "@angular/core";
import {DirectusFile} from "../interfaces/directus-file";

@Pipe({
  name: "fileType",
})
export class FileTypePipe implements PipeTransform {
  transform(file: DirectusFile): unknown {
    return file.type || null;
  }
}
