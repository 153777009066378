import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnDestroy } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { take, filter, ReplaySubject, takeUntil } from "rxjs";
import { environment } from "src/environments/environment";
import { PageData } from "./interfaces/page-data";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnDestroy {
  loading = true;

  unsubscribe$ = new ReplaySubject(1);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private titleService: Title
  ) {
    // TODO add analytics [create property for both test-sea and sea and chang the environment variable]
    // this.initGtag();
    this.subscribeToRouterEvents();
    this.subscribeToFragmentChange();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  subscribeToRouterEvents() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.loading = false;
        this.setPageTitle();
        window.scrollTo(0, 0);
        this.updateBodyClass();
      });
  }

  subscribeToFragmentChange() {
    this.route.fragment
      .pipe(
        filter((fragment) => !!fragment),
        take(1),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((fragment) => {
        if (!fragment) return;
        const interval = setInterval(() => {
          const element = this.document.getElementById(fragment);
          if (!element) return;
          element.scrollIntoView();
          clearInterval(interval);
        }, 500);
      });
  }

  updateBodyClass() {
    const isRoot = ["", "/"].includes(window.location.pathname);
    if (isRoot) {
      document.body.classList.remove("subPage");
      document.body.classList.remove("world-bg");
    } else {
      document.body.classList.add("subPage");
      document.body.classList.add("world-bg");
    }
  }
  setPageTitle() {
    let route = this.route;
    while (route.firstChild) {
      route = route.firstChild;
    }

    const { title } = route.snapshot.data["page"] as PageData;
    if (title) {
      this.titleService.setTitle(`${title} | المصطلحات التربوية`);
    } else {
      this.titleService.setTitle(`المصطلحات التربوية`);
    }
  }

  initGtag() {
    console.log("Initializing gtag");
    const script = this.document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.analytics.measurementId}`;
    script.type = "text/javascript";
    script.async = true;
    this.document.head.appendChild(script);

    // const windowRef: Window & {dataLayer: any[]; gtag: (...args: unknown[]) => void} = window as any;
    (window as any).dataLayer = (window as any).dataLayer || [];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function gtag(...args: unknown[]) {
      // eslint-disable-next-line prefer-rest-params
      (window as any).dataLayer.push(arguments);
    }

    gtag("js", new Date());
    gtag("config", environment.analytics.measurementId, {
      ...(environment.analytics.debug ? { debug_mode: true } : {}),
    });

    (window as any).gtag = gtag;
  }
}
