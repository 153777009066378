import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HomePage, LandingPageData } from "src/app/interfaces/landing-page";

@Component({
  selector: "app-landing-intro",
  templateUrl: "./landing-intro.component.html",
  styleUrls: ["./landing-intro.component.scss"],
})
export class LandingIntroComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}

  homePageIntro!: HomePage;
  ngOnInit(): void {
    const { meta } = this.route.snapshot.data["page"] as LandingPageData;
    this.homePageIntro = meta;
  }

  showHiddenText = false;

  toggleHiddenText() {
    this.showHiddenText = !this.showHiddenText;
    const showMoreLink = document.querySelector(".show-more");
    const hiddenText = document.querySelector(".hidden-text");
    if (hiddenText) hiddenText.classList.toggle("less");
    if (showMoreLink) showMoreLink.classList.toggle("less");
  }
}
