<nav
  id="navbar_top"
  class="navbar navbar-expand-lg py-1 py-md-0"
  [ngClass]="{
    'navbar-border': heightFlag,
    'fixed-top shadow fade-in-down fast': !heightFlag
  }"
>
  <div class="container">
    <a
      class="navbar-brand py-0 mx-auto ps-5 ps-md-0"
      routerLink="/"
      target="_self"
    >
      <!-- Small screens -->
      <img
        class="d-lg-none logo-sm"
        src="assets/images/abegs.svg"
        height="80px"
        alt="Logo"
      />

      <!-- Large screens -->
      <img
        class="d-none d-lg-block"
        src="assets/images/abegs-vertical-light.svg"
        height="82px"
        alt="Logo"
      />
    </a>

    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="offcanvas"
      data-bs-target="#mainOffcanvas"
      aria-label="sidebar"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div
      class="offcanvas offcanvas-start"
      tabindex="-1"
      id="mainOffcanvas"
      aria-labelledby="mainOffcanvasLabel"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="mainOffcanvasLabel"></h5>
        <button
          type="button"
          class="btn btn-link text-light fs-4"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <i class="bi bi-x-lg"></i>
        </button>
      </div>

      <div class="offcanvas-body" id="navbar-top">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li *ngFor="let link of navbarLinks" class="nav-item mx-2 my-lg-2">
            <a
              *ngIf="!link.external && !link.selfLink"
              target="_self"
              class="d-none d-lg-inline-flex nav-link small"
              [ngClass]="{ 'fw-bold': link.href.includes(currentRoute) }"
              [routerLink]="link.href"
            >
              {{ link.title }}
            </a>
            <!-- route for homepage large screen -->
            <a
              *ngIf="link.external || link.selfLink"
              target="_self"
              class="d-none d-lg-inline-flex nav-link small"
              [ngClass]="{ 'fw-bold': link.href.includes(currentRoute) }"
              [href]="link.href"
            >
              {{ link.title }}
            </a>

            <!-- Offcanvas -->

            <a
              *ngIf="!link.external && !link.selfLink"
              target="_self"
              class="d-lg-none d-flex justify-content-between nav-link small"
              [ngClass]="{ 'fw-bold': link.href.includes(currentRoute) }"
              [routerLink]="link.href"
            >
              {{ link.title }}
            </a>

            <!-- route for homepage small screen -->
            <a
              *ngIf="link.external || link.selfLink"
              target="_self"
              class="d-lg-none d-flex justify-content-between nav-link small"
              [ngClass]="{ 'fw-bold': link.href.includes(currentRoute) }"
              [href]="link.href"
            >
              {{ link.title }}
            </a>
          </li>

          <li class="nav-item mx-2 my-2">
            <a
              class="small btn btn-primary"
              target="_self"
              href="{{ cmsAdminURL }}"
              >التقديم في المسابقة</a
            >
          </li>
          <li class="nav-item my-lg-2">
            <a
              class="small btn btn-primary"
              href="mailto:abegs@abegs.org"
              target="_blank"
              >تواصل معنا</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
