import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable, map } from "rxjs";
import { Apollo } from "apollo-angular";
import { NewsPageData } from "../interfaces/news-page";
import {
  GET_NEWS_PAGE,
  GET_SINGLE_NEWS_PAGE,
} from "../queries/news-page.query";
import { environment } from "src/environments/environment";
import { SingleNewsPageData } from "../interfaces/single-news-page";

@Injectable({
  providedIn: "root",
})
export class SingleNewsPageResolver implements Resolve<SingleNewsPageData> {
  constructor(private apollo: Apollo) {}

  resolve(route: ActivatedRouteSnapshot): Observable<SingleNewsPageData> {
    return this.apollo
      .query({
        query: GET_SINGLE_NEWS_PAGE,
        variables: { singleNewsId: route.params["singleNewsId"] },
      })
      .pipe(
        map((result): SingleNewsPageData => {
          const { page: meta, ...page } = result.data;
          return {
            title: "أخبار وفعاليات",
            hero: {
              title: meta.title,
              image: meta.image,
            },
            data: page,
            meta,
          };
        })
      );
  }
}
