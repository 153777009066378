import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import AOS from "aos";

@Component({
  selector: "app-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"],
})
export class LandingPageComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    AOS.init({
      // disable: "mobile"
      once: true,
    });
    AOS.refresh(); //refresh method is called on window resize and so on, as it doesn't require to build new store with AOS elements and should be as light as possible.
  }
}
