<div class="container pt-4">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb mb-0">
      <li class="breadcrumb-item">
        <a class="text-light-gray" routerLink="/">الرئيسة</a>
      </li>
      <li *ngFor="let item of data; let last = last" class="breadcrumb-item" [ngClass]="{active: last}">
        <a *ngIf="!last" class="text-light-gray" [routerLink]="item.routerLink">{{ item.title }}</a>
        <span *ngIf="last">{{ item.title }}</span>
      </li>
    </ol>
  </nav>
</div>
