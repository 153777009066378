import {Component} from "@angular/core";

@Component({
  selector: "app-fonts-share",
  templateUrl: "./fonts-share.component.html",
  styleUrls: ["./fonts-share.component.scss"],
})
export class FontsShareComponent {
  readonly SMALLEST_FONT_SIZE = 10;
  readonly LARGEST_FONT_SIZE = 30;
  readonly FONT_SIZE_STEP = 2;

  currentFontSize = 12;

  get url() {
    return window.location.href;
  }

  increaseFontSize() {
    this.currentFontSize = Math.min(this.LARGEST_FONT_SIZE, this.currentFontSize + this.FONT_SIZE_STEP);
    this.updateDocumentFontSize();
  }

  decreaseFontSize() {
    this.currentFontSize = Math.max(this.SMALLEST_FONT_SIZE, this.currentFontSize - this.FONT_SIZE_STEP);
    this.updateDocumentFontSize();
  }

  updateDocumentFontSize() {
    document.documentElement.style.fontSize = `${this.currentFontSize}px`;
  }
}
