import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable, map } from "rxjs";
import { ContactUsPage } from "../interfaces/contact-us-page";

import { GET_CONTACT_US_PAGE } from "../queries/contact-us-page.query";

import { HttpClient } from "@angular/common/http";
import { LIST_NEWS_BY_PAGE } from "../queries/news-page.query";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CMSService {
  constructor(private apollo: Apollo, private httpClient: HttpClient) {}

  getContactUsDetails(): Observable<ContactUsPage> {
    return this.apollo
      .query({
        query: GET_CONTACT_US_PAGE,
      })
      .pipe(
        map((result): ContactUsPage => {
          const contactus = result.data.page;
          return contactus;
        })
      );
  }

  getActivitiesByPage(page: number): Observable<unknown> {
    return this.apollo
      .query({
        query: LIST_NEWS_BY_PAGE,
        variables: {
          newsLimit: environment.limit.newsLimit,
          page: page,
        },
      })
      .pipe(
        map((result): unknown => {
          return {
            news: result.data.news,
            pagingNews: result.data.pagingNews,
          };
        })
      );
  }
}
