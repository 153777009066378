import { gql } from "apollo-angular";
import {
  HomePage,
  AwardAbout,
  AwardMembership,
  AwardBudget,
  News,
} from "../interfaces/landing-page";

export const GET_LANDING_PAGE_DATA = gql<
  {
    page: HomePage;
    awardAbout: AwardAbout;
    awardMembership: AwardMembership;
    awardBudget: AwardBudget;
    news: News[];
  },
  void
>`
  query {
    page: home {
      title
      subtitle: sub_title
      intro
      introductionCont: introduction_cont
      image {
        id
        filename: filename_download
        title
      }
      guide {
        id
        filename: filename_download
        title
        type
      }
    }
    awardAbout: about {
      goals
      values
      vision
      targeted
      message
    }
    awardMembership: membership {
      conditions
      conditionImage: condition_image {
        id
        filename: filename_download
        title
      }
      steps
      stepsImage: steps_image {
        id
        filename: filename_download
        title
      }
      arbitration
      arbitrationImage: arbitration_image {
        id
        filename: filename_download
        title
      }
    }
    awardBudget: budget {
      title
      goldenTitle: golden_title
      goldenDescription: golden_description
      goldenImage: golden_image {
        id
        filename: filename_download
        title
      }

      silverTitle: silver_title
      silverDescription: silver_description
      silverImage: silver_image {
        id
        filename: filename_download
        title
      }

      bronzeTitle: bronze_title
      bronzeDescription: bronze_description
      bronzeImage: bronze_image {
        id
        filename: filename_download
        title
      }
    }
    news(
      filter: { status: { _eq: "published" } }
      sort: ["-date_created"]
      limit: 5
    ) {
      id
      title
      date
      image {
        id
        filename: filename_download
        title
      }
      content
    }
  }
`;
