import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {Direction} from "../enums/direction";
import {Locale} from "../enums/locale";

@Injectable({
  providedIn: "root",
})
export class LocalizationService {
  private locale = new BehaviorSubject<{code: Locale; direction: Direction}>({
    code: Locale.ARABIC,
    direction: Direction.RIGHT_TO_LEFT,
  });

  private stylesheets!: {[key in Direction]: HTMLLinkElement};

  constructor() {
    const rtlStylesheet = document.querySelector<HTMLLinkElement>("#rtl-stylesheet");
    const ltrStylesheet = document.querySelector<HTMLLinkElement>("#ltr-stylesheet");

    if (!rtlStylesheet || !ltrStylesheet) return;

    this.stylesheets = {
      [Direction.RIGHT_TO_LEFT]: rtlStylesheet,
      [Direction.LEFT_TO_RIGHT]: ltrStylesheet,
    };
  }

  getLocale() {
    return this.locale.asObservable();
  }

  toggleLocalization() {
    const locale = (document.documentElement.lang === "ar" ? "en" : "ar") as Locale;
    const rtl = locale === "ar";

    document.documentElement.lang = locale;
    document.documentElement.dir = rtl ? "rtl" : "ltr";

    if (rtl) {
      this.stylesheets[Direction.RIGHT_TO_LEFT].disabled = false;
      this.stylesheets[Direction.LEFT_TO_RIGHT].disabled = true;
    } else {
      this.stylesheets[Direction.RIGHT_TO_LEFT].disabled = true;
      this.stylesheets[Direction.LEFT_TO_RIGHT].disabled = false;
    }

    this.locale.next({
      code: rtl ? Locale.ARABIC : Locale.ENGLISH,
      direction: rtl ? Direction.RIGHT_TO_LEFT : Direction.LEFT_TO_RIGHT,
    });
  }
}
