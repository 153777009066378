<div class="bg-dark text-light py-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <h6 class="text-primary fw-bold">
          جائزة مكتب التربية العربي لدول الخليج للتميز المدرسي
        </h6>
        <hr class="my-4" style="border-color: #474747" />

        <ul class="footer-links list-unstyled mb-5 mt-4">
          <li
            *ngFor="let link of footerLinks"
            class="list-unstyled-item ps-2 ms-3 mb-3"
          >
            <a
              *ngIf="!link.external && !link.selfLink"
              [routerLink]="link.href"
              class="cursor-pointer"
              >{{ link.title }}</a
            >
            <a
              *ngIf="link.external || link.selfLink"
              [href]="link.href"
              target="_self"
              class="cursor-pointer"
              >{{ link.title }}</a
            >
          </li>
        </ul>
      </div>
      <div class="col">
        <h6 class="text-primary fw-bold">تواصل معنا</h6>
        <hr class="my-4" style="border-color: #474747" />

        <div *ngIf="contact" class="mt-4 text-light small">
          <div
            class="d-flex flex-column flex-md-row justify-content-md-between"
          >
            <div class="">
              <p class="">
                <i class="fa-solid fa-location-dot me-3"></i>
                {{ contact.address }}
              </p>
            </div>
          </div>

          <div
            class="d-flex flex-column flex-md-row justify-content-md-between"
          >
            <div class="">
              <p class="">
                <i class="fa-solid fa-phone fa-flip-horizontal me-3"></i>
                <a class="text-light" href="tel:{{ contact.phone }}">{{
                  contact.phone
                }}</a>
              </p>
            </div>
          </div>

          <div
            class="d-flex flex-column flex-md-row justify-content-md-between"
          >
            <div class="">
              <p class="">
                <i class="fa-solid fa-envelope me-3"></i>
                <a class="text-light" href="mailto: {{ contact.email }}">
                  {{ contact.email }}
                </a>
              </p>
            </div>
          </div>

          <div
            class="d-flex flex-column flex-md-row justify-content-md-between"
          >
            <div class="">
              <p class="">
                <i class="fa-solid fa-question fa-flip-horizontal me-3"></i>
                هل لديك أي أسئلة تود طرحها؟
              </p>
            </div>
            <div class="">
              <div class="d-flex social-media-links justify-content-md-end">
                <a
                  *ngFor="let link of socialMediaLinks"
                  class="ms-md-3 me-3 me-md-0 text-light"
                  target="_self"
                  href="{{ link.href }}"
                >
                  <i class="{{ link.iconClass }}"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
