import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, map } from "rxjs";
import { Apollo } from "apollo-angular";
import { NewsPageData } from "../interfaces/news-page";
import { GET_NEWS_PAGE } from "../queries/news-page.query";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class NewsPageResolver implements Resolve<NewsPageData> {
  constructor(private apollo: Apollo) {}

  resolve(): Observable<NewsPageData> {
    return this.apollo
      .query({
        query: GET_NEWS_PAGE,
        variables: { newsLimit: environment.limit.newsLimit },
      })
      .pipe(
        map((result): NewsPageData => {          
          const { page: meta, ...page } = result.data;
          return {
            title: "أخبار وفعاليات",
            hero: {
              title: meta.title,
              image: meta.image,
            },
            data: page,
            meta,
          };
        })
      );
  }
}
